


.divider {
  background-color: rgb(197, 197, 197);
}

.pagetitle {
  color: white;
}


.menuicon {
  background-color: rgb(26, 0, 138);
  border-color: rgb(26, 0, 138);
}

.loginbutton {
  background-color: rgb(26, 0, 138);
  border-color: rgb(26, 0, 138);

}

.searchicon {
  background-color: rgb(26, 0, 138);
  left: 50%;
  margin-top: -3em;
  font-weight: bold;
  z-index: 100;
  width: 5rem;
  height: 5rem;

}