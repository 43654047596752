.searchresultspagecontainer {
  background-color: rgb(26, 0, 138);
  color: white;
  font-size: 120%;

}

.listitem:hover {
  box-shadow: inset 0 0 0 10em rgba(26, 0, 138, 0.8);
  
}

.resultsdescription {
  font-family: 'Roboto Mono', monospace;
  font-size: 120%;
}
