@charset "utf-8";
$black: hsl(0, 0%, 4%);
$input-placeholder-color: #ADAAAA;
$input-border-color: #FFFFFF;
$input-icon-color: $black;
$button-border-color: $black;
$section-padding: 1.5rem 1.5rem;
$family-primary: "Helvetica Neue";
$family-secondary: "Helvetica Neue";
$family-sans-serif: "Helvetica Neue";
$modal-background-background-color: inherit;
@import "bulma/bulma.sass";
@import './fonts';
@import '~bulma-checkradio';
@import '~bulma-divider';
@import '~bulma-steps';
@import '~bulma-carousel';

.block:not(:last-child) {
  margin-bottom: 4rem;
}

html body {
  font-family: $family-primary;
}

a.is-link {
  text-decoration: underline;
}

.is-logo {
  font-family: 'Raleway Black';
  color: #11BCDD;
}

.is-family-raleway-black {
  font-family: 'Raleway Black';
}

.is-family-raleway-extra-bold-italic {
  font-family: 'Raleway Extra Bold Italic';
}

.is-family-raleway-bold-italic {
  font-family: 'Raleway Extra Bold Italic';
}

.is-family-raleway-semi-bold {
  font-family: 'Raleway Semi Bold';
}

.has-text-inactive {
  color: #9C9CA0;
}

.signupForm .input::-webkit-input-placeholder {
  color: #EDE7E7;
}

.has-text-maininput {
  color: #ADAAAA !important;
}

.has-background-signup, .has-background-signup:valid {
  background: #11BCDD 0% 0% no-repeat padding-box;
}

.has-background-modal {
  background: #FDFAFA 0% 0% no-repeat padding-box;
  opacity: 0.92;
}

.has-text-footer {
  color: #797B7E;
}

.has-departments-color {
  color: #0C6A7C !important;
}

.is-parallelogram {
  transform: skew(-30deg);
  span {
    transform: skew(30deg);

  }

}

.has-background-header {
  background: #FDFAFA 0% 0% no-repeat padding-box;
}

.has-searchbtn {
  background: #11BCDD 0% 0% no-repeat padding-box;
  border-width: 0px;
  border-radius: 0px 13px 13px 0px;
}

.border-radius-left {
  border-radius: 13px 0px 0px 13px;

}

.is-rounded-left {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.has-background-mainpage {
  opacity: 1;
  background: transparent linear-gradient(180deg, #0C9BB7 0%, #5BBDD1 57%, #B4DDE5 100%) 0% 0% no-repeat padding-box;
}

.slider-navigation-next, .slider-navigation-previous {
  display: none;
}

.has-select-centered {
  text-align-last: center;

}
