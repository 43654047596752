@font-face {
  font-family: 'Raleway Black';
  src: url('./../fonts/Raleway-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Raleway Semi Bold';
  src: url('./../fonts/Raleway-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Raleway Bold Italic';
  src: url('./../fonts/Raleway-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Raleway Extra Bold Italic';
  src: url('./../fonts/Raleway-ExtraBoldItalic.ttf') format('truetype');
}