.header {
  background-color: rgb(26, 0, 138)
}

.pagetitle {
  color: white;
}

.backButton {
  color: whitesmoke;
  background-color: rgb(26, 0, 138);
  font-size: 120%;
}