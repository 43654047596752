.firstrow {
  color: white;
  background: url('https://res.cloudinary.com/taho-software-services/image/upload/v1596406986/crewder/background/intro-bg-1_tgz6mb.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 70vh;

}

hr {
  margin-top: 0;
  margin-bottom: 0;
}

.secondrow {
  font-family: 'Abel', sans-serif;
  font-weight: bold;
  background: url('https://res.cloudinary.com/taho-software-services/image/upload/v1596542399/crewder/background/intro-bg-2_sjeqv9.png');
  background-repeat: no-repeat;
  background-size: cover;
  color: rgb(137,193,36);
}

.firstrowimage1 {
  left: 30%;
  top: 50px;
  z-index: 100;
  height: 40vh;
}

.firstrowimage2 {
  left: 44%;
  z-index: 99;
  height: 40vh;
}

.secondrowimage1 {
  left: 60%;
  top: 0;
  height: 40vh;

}

@media (max-width: 768px) { 
  .title {
    font-size: 26px;
    
  }

  .position-absolute {
    position: static !important;
  }
}

.list {
  font-size: 160%;
}
